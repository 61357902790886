/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import primeNumber from "../../../../images/Number Theory/prime-numbers.webp";
import {SEO} from "smooth-doc/src/components/SEO";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    ul: "ul",
    li: "li",
    h3: "h3",
    pre: "pre",
    code: "code",
    blockquote: "blockquote"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "prime-numbers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#prime-numbers",
    "aria-label": "prime numbers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Prime Numbers"), "\n", React.createElement(_components.p, null, "and program to check if a number is Prime number or not in Rust."), "\n", React.createElement(_components.h2, {
    id: "what-are-the-prime-numbers-",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-prime-numbers-",
    "aria-label": "what are the prime numbers  permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are the Prime Numbers ?"), "\n", React.createElement(_components.p, null, "Prime Numbers are the natural number greater than 1, that are divisible only by 1 and the number itself."), "\n", React.createElement(_components.p, null, "Alternatively, you can say that prime numbers are the natural numbers greater than 1 that have only 2 factors, that is 1 and the number itself."), "\n", React.createElement(_components.p, null, "Smallest Prime number is 2."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "For Example"), " : 19 is a prime number. It is only divisible by 1 and 19 itself."), "\n", "\n", React.createElement("div", {
    style: {
      textAlign: 'center'
    }
  }, React.createElement("img", {
    src: primeNumber,
    width: "100%",
    alt: "Prime Numbers less than 20"
  })), "\n", React.createElement(_components.h2, {
    id: "properties-of-prime-numbers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#properties-of-prime-numbers",
    "aria-label": "properties of prime numbers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Properties of Prime Numbers"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "All prime numbers except 2 are odd numbers. 2 is also the smallest prime number."), "\n", React.createElement(_components.li, null, "2 prime numbers are always co prime to each other, that is their GCD = 1."), "\n", React.createElement(_components.li, null, "Every natural number greater than 1 can be expressed as product of powers of prime numbers, which is unique. See ", React.createElement(_components.a, {
    href: "/number-theory/prime-factorization-of-a-number/"
  }, "Prime Factorization of a number")), "\n", React.createElement(_components.li, null, "Every even natural number greater than 2 can be expressed as the sum of two prime numbers."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Fermat's_little_theorem"
  }, "Fermat's little theorem"), ", for any number n, and a prime number p,  ", React.createElement("b", null, " ( n", React.createElement("sup", null, " p-1"), " )  mod p = 1 ")), "\n", React.createElement(_components.li, null, "Every prime number greater than 3 can be written as 6n+1 or 6n-1, where n is any natural number."), "\n"), "\n", React.createElement(_components.h2, {
    id: "check-if-a-number-is-prime",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#check-if-a-number-is-prime",
    "aria-label": "check if a number is prime permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Check if a number is Prime"), "\n", React.createElement(_components.p, null, "Let us now make a function to check if a given number is prime or not. We iterate through all the number from 2 to square root of number and check if it divides the number. If yes, return false."), "\n", React.createElement(_components.p, null, "Finally, return true because it not divisible by any number."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Proof")), "\n", React.createElement(_components.p, null, "Let us suppose, that some number, N is not prime. So, we can write it as"), "\n", React.createElement(_components.p, null, "N = A × B"), "\n", React.createElement(_components.p, null, "and 1 < A ≤ B < N. So, A must be less than or equal to square root of N, else A × B will become greater than N, because A × A > N and A ≤ B => A × B > N."), "\n", React.createElement(_components.p, null, "Hence, we have to check for the numbers only upto sqrt( N )."), "\n", React.createElement(_components.h3, {
    id: "program",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#program",
    "aria-label": "program permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Program"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "\nfn check_prime(n:usize) -> bool{\n\n    // Iterate from i = 2 to sqrt ( n )\n    let mut i:usize = 2;\n    while i*i<=n {\n        // Return false if the number is divisible\n        if n%i == 0 {\n            return false;\n        }\n        i+=1;\n    }\n\n    // Return true finally\n    return true;\n}\n")), "\n", React.createElement(_components.p, null, "with Driver code"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "use std::io::stdin;\n\nfn take_int() -> usize {\n    let mut input = String::new();\n    stdin().read_line(&mut input).unwrap();\n    return input.trim().parse().unwrap();\n}\n\nfn check_prime(n:usize) -> bool{\n\n    // Iterate from i = 2 to sqrt ( n )\n    let mut i = 2;\n    while i*i<=n {\n        // Return false if the number is divisible\n        if n%i == 0 {\n            return false;\n        }\n        i+=1;\n    }\n\n    // Return true finally\n    return true;\n}\n\npub fn main() {\n    // Take the number from user\n    let n = take_int();\n\n    // Output on the basis of if the number is prime or not\n\n    if check_prime(n) {\n        println!(\"Yes, given number is a prime number\");\n    }\n    else {\n        println!(\"No, given number is not a prime number\");\n    }\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Input")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "1000000007"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Output")), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Yes, given number is a prime number"), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Time Complexity : O( sqrt( N ) )"), " ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Space Complexity : O( 1 )")), "\n", React.createElement(_components.h2, {
    id: "conclusion",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#conclusion",
    "aria-label": "conclusion permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Conclusion"), "\n", React.createElement(_components.p, null, "Prime numbers is a very important concept, especially from Competitive programming point of view. They have plenty of interesting properties and interesting patterns."), "\n", React.createElement(_components.p, null, "In this article, we discussed some of the properties of prime numbers, as well as made a program to check whether a given number is prime or not in square root time complexity."), "\n", React.createElement(_components.p, null, "Here is the optimized function for easy access"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-rust"
  }, "fn check_prime(n:usize) -> bool{\n    let mut i = 2;\n    while i*i<=n {\n        if n%i == 0 { return false; }\n        i+=1;\n    }\n    return true;\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Thank You")), "\n", "\n", React.createElement(SEO, {
    title: "Prime Numbers and function to check if a number is prime - Rust Programming",
    description: "Prime numbers are the numbers which are divisible only by 1 and the number itself. We will discuss some of the properties of Prime numbers and make a program to check if a given number is prime number or not in Rust."
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
